export const config = {
  api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '/img/pinocchios-logo.png',
    banner_img_root : '/img/pinocchios-banner.png',
    resid : '',
    key_value : 'pinocchiosfortcollins',
    secret_value : 'pinocchiosfortcollins',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3032.6381428432173!2d-105.07868378517665!3d40.527488579352415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87694c802a11893f%3A0x36fc986917b895d1!2s4306%20S%20College%20Ave%2C%20Fort%20Collins%2C%20CO%2080525%2C%20USA!5e0!3m2!1sen!2sin!4v1582806257757!5m2!1sen!2sin',
    facebook_link:'#',
    RestaurantName:"Pinocchios Fort Collins Order Online"
};
